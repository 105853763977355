<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<!-- (구)>AI 판별 기준 설정 -->
			<b>AI-threshold 설정</b>
		</div>
		<div>
			<div id="treeView">
				<div class="demo-section wide k-content">
					<div class="treeview-flex">
						<div id="treeview-kendo"></div>
					</div>
				</div>
			</div>
			<div class="content">
				<div class="wrapper">
					<h5 class="panel-title" style="padding-bottom: 10px; padding-top: 50px"><b>AI-threshold 설정</b></h5>
					<div class="table-wrapper" warnWork>
						<table class="table table-hover">
							<thead>
								<tr>
									<th>대상물</th>
									<th>탐지 기준 (퍼센트)</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(row, index) in aiConfigDistigshSutbDgreCctv.insAiConfigDistigshSutbDgreCctvInVOs"
									:key="index"
									:id="'dist' + index"
									class="dist-tr"
								>
									<td style="text-align: left">{{ row.objectNm }}</td>
									<td>
										<input
											type="number"
											v-model="row.aiDistigshSutbDgre"
											@keyup="onlyNumLimitLen(index, $event, limitLength.aiDistigshSutbDgre), checkChangeValue(index)"
										/>
									</td>
								</tr>
								<tr empty>
									<td colspan="2">조회된 항목이 없습니다.</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="mb-10 mt-10 text-right">
						<button @click="saveAiConfigDistigshSutbDgreCctv" class="btn btn-labeled bg-primary">
							<b><i class="icon-checkmark3"></i></b>
							적용
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import apiIndex from '../../api/index';
import _ from 'lodash';

const aiConfigDistigshSutbDgreCctvApi = apiIndex.aiConfigDistigshSutbDgreCctv;

let axiosExtention; //eslint-disable-line no-unused-vars

export default {
	data: () => ({
		pageParam: null,
		userSiteId: null,
		nowSelect: {
			siteId: null, // 현재 선택된 현장
			cctvId: null, // 현재 선택된 CCTV
		},
		siteAndCctv: [], // 트리에 표시, 사용할 데이터
		dataSource: [], // 파싱하여 트리에 보여줄 데이터 소스
		aiConfigDistigshSutbDgreCctv: {
			insAiConfigDistigshSutbDgreCctvInVOs: [], // 수정 후 데이터
			originalInsAiConfigDistigshSutbDgreCctvInVOs: [], // 수정 전 원본 데이터
		},
		limitLength: {
			aiDistigshSutbDgre: 3, // 탐지 기준 길이 제한
		},
	}),
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('aiConfigDistigshSutbDgreCctvPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;

		axiosExtention = this.$jarvisExtention.axiosExtention;

		this.loginUserInfo = JSON.parse(sessionStorage.getItem('loginUserInfo'));
		this.userSiteId = this.loginUserInfo.siteId;

		this.siteAndCctv = this.pageParam.inqSiteAndCctv;
	},
	mounted() {
		this.parsingDataSource();
		// 세팅된 데이터로 트리 그리기
		this.drawTreeView();
	},
	methods: {
		//  트리에 사용할 데이터 DataSource에 파싱하기
		parsingDataSource() {
			// 현장 밑에 CCTV 설정
			for (let siteElement of this.siteAndCctv) {
				let site = {};
				site.id = siteElement.siteId;
				site.text = siteElement.siteNm;
				site.expanded = true;
				site.spriteCssClass = 'site';
				site.key = siteElement.siteId;

				let cctvItems = [];
				if (Object.keys(siteElement.cctvInfo).length) {
					for (let cctvKey in siteElement.cctvInfo) {
						let cctvItem = {};
						cctvItem.id = cctvKey;
						cctvItem.text = siteElement.cctvInfo[cctvKey];
						cctvItem.expanded = false;
						cctvItem.spriteCssClass = 'cctv';
						cctvItem.key = cctvKey;
						cctvItem.parentSiteId = site.id;
						cctvItem.parentSiteNm = site.text;
						cctvItems.push(cctvItem);
					}
					site.items = cctvItems;
				}

				this.dataSource.push(site);
			}
		},
		// 트리 그리기
		drawTreeView() {
			$('#treeview-kendo').kendoTreeView({
				dataSource: this.dataSource,
				loadOnDemand: true,
				select: this.treeSelect,
			});

			if (this.userSiteId != null) {
				const that = this;
				$('#treeview-kendo')
					.find('ul > li')
					.each(function () {
						// 로그인한 현장과 같은 현장이 있고
						if ($('#treeview-kendo').data('kendoTreeView').dataItem($(this)).parentSiteId === that.userSiteId) {
							// 젤 처음 나오는 cctv면
							if ($('#treeview-kendo').data('kendoTreeView').dataItem($(this)).spriteCssClass === 'cctv') {
								// 클릭 이벤트 발생
								$(this).find('.k-in').trigger('click');
								return false;
							}
						}
					});
			}
		},
		// 트리 클릭 시
		async treeSelect(e) {
			let data = $('#treeview-kendo').data('kendoTreeView').dataItem(e.node);
			this.clickedAllCheckedEvent = false; // 전체 선택 버튼 초기화
			if (data.spriteCssClass == 'cctv') {
				await this.$axios
					.post(aiConfigDistigshSutbDgreCctvApi.inqAiConfigDistigshSutbDgreCctv, { siteId: data.parentSiteId, cctvId: data.id })
					.then(
						function (r) {
							this.nowSelect.siteId = data.parentSiteId;
							this.nowSelect.cctvId = data.id;
							this.aiConfigDistigshSutbDgreCctv.insAiConfigDistigshSutbDgreCctvInVOs = _.cloneDeep(r.data);
							this.aiConfigDistigshSutbDgreCctv.originalInsAiConfigDistigshSutbDgreCctvInVOs = _.cloneDeep(r.data);
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			} else if (data.spriteCssClass == 'site') {
				this.$nextTick(function () {
					// site 클릭 시 옆에 아이콘 클릭과 같은 효과 주기
					$(e.node).find('.k-icon').trigger('click');
					// 클릭 시 생기는 파란선 없애기
					$(e.node).find('.k-state-selected').removeClass('k-state-selected');
					// 클릭 시 생기는 옆에 테두리 없애기
					$(e.node).find('.k-state-focused').removeClass('k-state-focused');
				});
			}
		},
		// 관계 등록
		saveAiConfigDistigshSutbDgreCctv() {
			// 유효성 검사
			for (let i = 0; i < this.aiConfigDistigshSutbDgreCctv.insAiConfigDistigshSutbDgreCctvInVOs.length; i++) {
				let element = this.aiConfigDistigshSutbDgreCctv.insAiConfigDistigshSutbDgreCctvInVOs[i];
				if (element.aiDistigshSutbDgre > 100 || element.aiDistigshSutbDgre < 0) {
					alert('값은 0 ~ 100 까지 입력 가능합니다.');
					return false;
				}
			}

			if (confirm('적용하시겠습니까?')) {
				// 수정된 값에 선택된 현장과 선택된 CCTV 값 넣기
				this.aiConfigDistigshSutbDgreCctv.insAiConfigDistigshSutbDgreCctvInVOs.forEach(element => {
					element.siteId = this.nowSelect.siteId;
					element.cctvId = this.nowSelect.cctvId;
				});

				// 원본 값에 선택된 현장과 선택된 CCTV 값 넣기
				this.aiConfigDistigshSutbDgreCctv.originalInsAiConfigDistigshSutbDgreCctvInVOs.forEach(element => {
					element.siteId = this.nowSelect.siteId;
					element.cctvId = this.nowSelect.cctvId;
				});

				this.$axios
					.post(aiConfigDistigshSutbDgreCctvApi.saveAiConfigDistigshSutbDgreCctv, this.aiConfigDistigshSutbDgreCctv)
					.then(
						function () {
							// 다시 불러오기
							this.$axios
								.post(aiConfigDistigshSutbDgreCctvApi.inqAiConfigDistigshSutbDgreCctv, {
									siteId: this.nowSelect.siteId,
									cctvId: this.nowSelect.cctvId,
								})
								.then(
									function (r) {
										// 색칠된 값 지우기
										document.querySelectorAll('.dist-tr').forEach(element => {
											element.classList.remove('changed-value');
										});
										this.aiConfigDistigshSutbDgreCctv.insAiConfigDistigshSutbDgreCctvInVOs = _.cloneDeep(r.data);
										this.aiConfigDistigshSutbDgreCctv.originalInsAiConfigDistigshSutbDgreCctvInVOs = _.cloneDeep(r.data);
										alert('적용되었습니다.');
									}.bind(this),
								)
								.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			}
		},
		// 탐기 기준 값이 전과 달라지면 색칠
		checkChangeValue(index) {
			let element = document.getElementById('dist' + index);
			if (
				this.aiConfigDistigshSutbDgreCctv.originalInsAiConfigDistigshSutbDgreCctvInVOs[index].aiDistigshSutbDgre !=
				this.aiConfigDistigshSutbDgreCctv.insAiConfigDistigshSutbDgreCctvInVOs[index].aiDistigshSutbDgre
			) {
				element.classList.add('changed-value');
			} else {
				element.classList.remove('changed-value');
			}
		},
		onlyNumLimitLen(index, event, limitLength) {
			let target = event.target;
			let value = target.value;
			value = this.$jarvisExtention.inputDataController.onlyNumLimitLen(value, limitLength);
			// 화면과 데이터 값 불일치 수정
			this.aiConfigDistigshSutbDgreCctv.insAiConfigDistigshSutbDgreCctvInVOs[index].aiDistigshSutbDgre = value;
			target.value = value;
		},
	},
};
</script>

<style>
[v-cloak] > * {
	display: none !important;
}

#rootVM > div > div {
	flex: 1;
	border: 1px solid #ddd;
	display: inline-flex;
	flex-direction: column;
}

#rootVM > div > .content {
	width: 79%;
	height: 890px;
	padding: 10px;
}

#treeView {
	width: 20%;
	height: 890px;
	margin-right: 1%;
	font-size: 15px;
	font-weight: bold;
	line-height: 15px;
	overflow: auto;
}

.treeview-flex h4 {
	color: #656565;
	margin-bottom: 1em;
	text-align: center;
}

.treeview-flex {
	flex: 1;
	-ms-flex: 1 0 auto;
}

.demo-section {
	margin-bottom: 5px;
	overflow: auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

#demo-section-title {
	width: 100%;
	flex: auto;
}

#demo-section-title h3 {
	margin-bottom: 2em;
	text-align: center;
}
.table {
	table-layout: fixed;
}

.table-wrapper {
	border-collapse: collapse;
	overflow-x: auto;
	overflow-y: auto;
	border-color: #dedee0;
	border-style: solid;
	border-width: 1px 1px 1px 1px;
	position: relative;
	color: #515967;

	background-color: #fff;
}

/* table head */
.table-wrapper .table > thead > tr > th {
	background-color: #f5f5f5;

	text-align: center;

	padding: 0.5em 0.6em 0.4em 0.6em;
	font-weight: 700;
	white-space: nowrap;
	text-overflow: ellipsis;

	border-color: #dedee0;
	border-style: solid;
	border-width: 0 0 0 1px;

	overflow: auto;
	position: sticky;
	top: 0;
	box-shadow: inset 0 -1px 0 #dedee0;
}

.table-wrapper .table > thead > tr > th:first-child {
	border-width: 0 0 0 0;
}

/* table body */
/* 홀수 */
.table-wrapper .table > tbody > tr:nth-child(2n + 1) {
	background-color: #fff;
}
/* 짝수 */
.table-wrapper .table > tbody > tr:nth-child(2n) {
	background-color: #fbfbfb;
}

.table-wrapper .table > tbody > tr:hover {
	background-color: #b5bdcb;
	background-image: url(/lib/kendo-r2-2019/styles/textures/highlight.png);
	background-position: 50% 50%;
}

.table-wrapper .table > tbody > tr > td:first-child {
	border-width: 0 0 0 0;
}

.table-wrapper .table > tbody > tr > td {
	border-color: #dedee0;
	border-style: solid;
	border-width: 0 0 0 1px;
	padding: 0.4em 0.6em;
	overflow: hidden;
	line-height: 1.6em;
	vertical-align: middle;
	text-overflow: ellipsis;
}

.table-wrapper .table > tbody > tr > td {
	text-align: center;
}

/* table empty */
.table [empty] {
	display: none;
	height: 166px;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}

.table-wrapper .table > tbody > tr > td:not(:first-child) > input {
	border: none;
	width: 100%;
	height: 100%;
	background-color: transparent;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
	text-align: right;
}

.changed-value {
	background-color: yellow !important;
}

.site {
	background-size: 100%;
	background-image: url('/images/icon-site.png');
}

.cctv {
	background-size: 100%;
	background-image: url('/images/icon-cctv.png');
}
</style>
